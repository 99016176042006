import React, { useState } from "react";
import FooterContent from "./FooterContent";
import { useFormik } from "formik";

// Country codes data with validation patterns
const countryCodes = [
  {
    code: "+1",
    country: "US/Canada",
    pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
    format: "(XXX) XXX-XXXX",
    minLength: 10,
    maxLength: 10,
  },
  {
    code: "+44",
    country: "UK",
    pattern: /^(0?[1-9]\d{8,9})$/,
    format: "XXXX XXX XXX",
    minLength: 10,
    maxLength: 11,
  },
  {
    code: "+91",
    country: "India",
    pattern: /^[6-9]\d{9}$/,
    format: "XXXXX XXXXX",
    minLength: 10,
    maxLength: 10,
  },
  {
    code: "+61",
    country: "Australia",
    pattern: /^(?:4|04|\+614)(?:\d{8}|\d{9})$/,
    format: "XXXX XXX XXX",
    minLength: 9,
    maxLength: 10,
  },
  {
    code: "+86",
    country: "China",
    pattern: /^1[3-9]\d{9}$/,
    format: "XXX XXXX XXXX",
    minLength: 11,
    maxLength: 11,
  },
  {
    code: "+49",
    country: "Germany",
    pattern: /^(1[567]\d{8,12}|[2-9]\d{10,11})$/,
    format: "XXXXX XXXXXXX",
    minLength: 10,
    maxLength: 13,
  },
];

const DeleteAccount = (props) => {
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(countryCodes[0]);
  const [formattedNumber, setFormattedNumber] = useState("");

  // Function to format phone number based on country
  const formatPhoneNumber = (value, country) => {
    // Remove all non-digit characters
    const numberOnly = value.replace(/\D/g, "");

    let formatted = "";
    switch (country.code) {
      case "+1": // US/Canada
        if (numberOnly.length >= 6) {
          formatted = `(${numberOnly.slice(0, 3)}) ${numberOnly.slice(
            3,
            6
          )}-${numberOnly.slice(6)}`;
        } else if (numberOnly.length >= 3) {
          formatted = `(${numberOnly.slice(0, 3)}) ${numberOnly.slice(3)}`;
        } else {
          formatted = numberOnly;
        }
        break;

      case "+91": // India
        if (numberOnly.length >= 5) {
          formatted = `${numberOnly.slice(0, 5)} ${numberOnly.slice(5)}`;
        } else {
          formatted = numberOnly;
        }
        break;

      default:
        // Generic formatting with spaces every 4 digits
        formatted = numberOnly.replace(/(\d{4})/g, "$1 ").trim();
    }

    return formatted;
  };

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = "Name is required";
    } else if (values.name.length < 3) {
      errors.name = "Name must be at least 3 characters long";
    }

    if (!values.number) {
      errors.number = "Phone number is required";
    } else {
      // Remove all non-digit characters for validation
      const cleanNumber = values.number.replace(/\D/g, "");

      // Check if number meets country-specific requirements
      if (
        cleanNumber.length < selectedCountry.minLength ||
        cleanNumber.length > selectedCountry.maxLength
      ) {
        errors.number = `Phone number must be between ${selectedCountry.minLength} and ${selectedCountry.maxLength} digits for ${selectedCountry.country}`;
      } else if (!selectedCountry.pattern.test(cleanNumber)) {
        errors.number = `Invalid phone number format for ${selectedCountry.country}`;
      }
    }

    if (!values.email) {
      errors.email = "Email is required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    if (!values.message || values.message.trim() === "") {
      errors.message = "Message is required";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      number: "",
      email: "",
      message: "",
    },
    validate,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);

      // Combine country code with formatted phone number
      const fullPhoneNumber = `${selectedCountry.code} ${values.number}`;
      const submissionValues = {
        ...values,
        number: fullPhoneNumber,
      };

      setTimeout(() => {
        console.log("Form submitted with values:", submissionValues);
        alert("Your account deletion request has been submitted successfully!");
        resetForm();
        setFormattedNumber("");
        setLoading(false);
      }, 1500);
    },
  });

  const handleCountryChange = (e) => {
    const country = countryCodes.find((c) => c.code === e.target.value);
    setSelectedCountry(country);
    formik.setFieldValue("number", ""); // Reset phone number when country changes
    setFormattedNumber("");
  };

  const handlePhoneChange = (e) => {
    let value = e.target.value.replace(/\D/g, ""); // Remove non-digits

    // Limit input length based on country's maxLength
    if (value.length > selectedCountry.maxLength) {
      value = value.slice(0, selectedCountry.maxLength);
    }

    const formatted = formatPhoneNumber(value, selectedCountry);
    setFormattedNumber(formatted);
    formik.setFieldValue("number", formatted);
  };

  return (
    <section id="delete-account" className="text-black">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="lead mb-4">Delete Account</h1>
            <div className="py-2">
              <form
                className="custom_contact_form"
                onSubmit={formik.handleSubmit}
              >
                <div className="form-group">
                  <label htmlFor="name">
                    Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      formik.touched.name && formik.errors.name
                        ? "is-invalid"
                        : ""
                    }`}
                    id="name"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    placeholder="Enter Name"
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="invalid-feedback">{formik.errors.name}</div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="number">
                    Phone Number <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <select
                      className="form-control"
                      style={{ maxWidth: "140px" }}
                      value={selectedCountry.code}
                      onChange={handleCountryChange}
                    >
                      {countryCodes.map(({ code, country }) => (
                        <option key={code} value={code}>
                          {code} {country}
                        </option>
                      ))}
                    </select>
                    <input
                      type="tel"
                      className={`form-control ${
                        formik.touched.number && formik.errors.number
                          ? "is-invalid"
                          : ""
                      }`}
                      id="number"
                      name="number"
                      onChange={handlePhoneChange}
                      onBlur={formik.handleBlur}
                      value={formattedNumber}
                      placeholder={`Format: ${selectedCountry.format}`}
                    />
                  </div>
                  {formik.touched.number && formik.errors.number && (
                    <div className="text-danger mt-1 small">
                      {formik.errors.number}
                    </div>
                  )}
                  <small className="form-text text-muted">
                    Format: {selectedCountry.format}
                  </small>
                </div>

                <div className="form-group">
                  <label htmlFor="email">
                    Email Address <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className={`form-control ${
                      formik.touched.email && formik.errors.email
                        ? "is-invalid"
                        : ""
                    }`}
                    id="email"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    placeholder="Enter Email"
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className="invalid-feedback">
                      {formik.errors.email}
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="message">
                    Message <span className="text-danger">*</span>
                  </label>
                  <textarea
                    className={`form-control ${
                      formik.touched.message && formik.errors.message
                        ? "is-invalid"
                        : ""
                    }`}
                    rows="3"
                    id="message"
                    name="message"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.message}
                    placeholder="Enter your message"
                  />
                  {formik.touched.message && formik.errors.message && (
                    <div className="invalid-feedback">
                      {formik.errors.message}
                    </div>
                  )}
                </div>

                <button
                  type="submit"
                  className="btn btn-primary custom_form_subbmit"
                  style={{ width: "45%" }}
                  disabled={loading}
                >
                  {loading ? "Submitting..." : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <FooterContent />
    </section>
  );
};

export default DeleteAccount;
