// import './assets/css/bootstrap.min.css';
import "bootstrap/dist/css/bootstrap.min.css";
import ReactDOM from "react-dom";
import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { Provider, useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { applyMiddleware, createStore } from "redux";
import "./App.css";
// import './assets/css/posts-non.css';
import "./assets/css/custome.css";
import "./assets/css/style_old.css";
// import './assets/css/opportunity.css';
// import './assets/css/opportunity-non.css';
// import './assets/css/cases.css';
// import './assets/css/surgery-post-view.css';
// import './assets/css/list-surgen.css';
// import './assets/css/posts.css';
import favicon from "./assets/img/favicon.png";
import "./assets/plugins/fontawesome/css/all.min.css";
import "./assets/plugins/fontawesome/css/fontawesome.min.css";
import InnerContent from "./components/InnerContent";
import rootReducer from "./reducers/rootReducer";
import thunk from "redux-thunk";

const store = createStore(rootReducer, applyMiddleware(thunk));

function App() {
  // console.log = function () {};
 

  React.useEffect(() => {
    // console.log = function () {};

    document.addEventListener(
      "showTost",
      (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (event.type !== "showTost") return;
        const { type, message, autoClose } = event.tostConfig;
        const tostObj = {
          position: "top-right",
          autoClose: autoClose || 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        };

        if (type === "success") {
          toast.success(message, tostObj);
        } else if (type === "error") {
          toast.error(message, tostObj);
        } else if (type === "warning") {
          toast.warn(message, tostObj);
        } else if (type === "info") {
          toast.info(message, tostObj);
        }
      },
      false
    );
  }, []);

  useEffect(() => {
    ReactGA.initialize("G-JHPDJYV3XM");
    return () => {};
  }, []);

  useEffect(() => {
    document.addEventListener(
      "contextmenu",
      (e) => {
        e.preventDefault();
      },
      []
    );
  });

  

  return (
    <React.Fragment>
      <Provider store={store}>
        <div className="application">
          <Helmet>
            <meta charset="utf-8" />
            <meta
              name="viewport"
              content="width=device-width, initial-scale=1.0, user-scalable=0"
            />
            <title>Surgeons For Surgeons</title>

            <link type="image/x-icon" href={favicon} rel="icon" />
          </Helmet>
        </div>
        {/* <HeaderContent></HeaderContent> */}
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          // pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        {/* {tosterMessage} */}
        {/* <ToastContainer /> */}

        <InnerContent></InnerContent>
        {/* <FooterContent></FooterContent> */}
        <div className="sidebar-overlay"></div>
        {/* <ChatSupport phoneNumber={"8828103808"} /> */}
      </Provider>
      {ReactDOM.createPortal(
        <MobileAppRedirectionDialog />,
        document.getElementById("app_redirection_portal")
      )}
    </React.Fragment>
  );
}

const google_play_app_link =
    "https://play.google.com/store/apps/details?id=com.sfs_mobile_app",
  ios_app_link =
    "https://apps.apple.com/in/app/surgeons-for-surgeons/id1660956060";

const MobileAppRedirectionDialog = () => {
  const dialogRef = React.useRef();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (dialogRef?.current) {
      setOpen(true);
      dialogRef.current.showModal();
    }
  }, []);

  React.useEffect(() => {
    setOpen(true);
  }, [open]);

  return (
    <dialog
      ref={dialogRef}
      open={open}
      tabIndex={1}
      onKeyDown={(e) => {
        e.preventDefault();
      }}
    >
      <div className="modal_layout">
        {/* <h4 id="subtitle">
          Surgeons for Surgeons Website has been synced with our App
        </h4> */}
        <div id="type_container">
          <h2>
          The website is undergoing an upgrade. We will be up and running soon.
          </h2>
          {/* <h2>
            Our Mobile <br />
            App is Live now!
          </h2> */}
          {/* <h6>Download our App & Use all website features!</h6> */}
          {/* <h6>The website is undergoing an upgrade. We will be up and running in 6 hours.</h6> */}
        </div>
        <picture id="gfx_container">
          <source
            media="(max-width:720px)"
            srcset="./assets/dialog-iphone-sm.png"
          />
          <source
            media="(min-width:721px)"
            srcset="./assets/dialog-iphone-lg.png"
          />
          <img src="./assets/dialog-iphone-lg.png" alt="" />
        </picture>

        <div id="app_links">
          <h3>DOWNLOAD THE APP!</h3>
          <div className="link_row">
            <a href={ios_app_link} target="_blank" rel="noopener noreferrer">
              <img src="./assets/app-store.png" alt="" />
            </a>
            <a
              href={google_play_app_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="./assets/play-store.png" alt="" />
            </a>
          </div>
        </div>
      </div>
    </dialog>
  );
};

export default App;
